<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col class="py-0" cols="12" sm="12" md="8" lg="6" xl="6">
        <div class="card card-custom gutter-b">
          <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
              <h5 class="card-label font-weight-bolder text-dark">
                Kata laluan baharu
              </h5>
              <span class="text-muted font-weight-bold font-size-sm mt-1"
                >Kata laluan baharu mestilah lain dari kata laluan asal.</span
              >
            </div>
          </div>
          <div class="card-body py-0 px-0">
            <form class="form" id="kt_reset_password_form">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-alert"
                    >Kata laluan baharu</label
                  >
                  <div class="col-lg-9 col-xl-6">
                    <input
                      type="password"
                      class="form-control form-control-lg form-control-solid"
                      value=""
                      placeholder="Kata laluan baharu"
                      name="new_password"
                      ref="new_password"
                      minlength="6"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label"
                    >Pengesahan kata laluan</label
                  >
                  <div class="col-lg-9 col-xl-6">
                    <input
                      type="password"
                      class="form-control form-control-lg form-control-solid"
                      value=""
                      placeholder="Pengesahan kata laluan"
                      name="verify_password"
                      ref="verify_password"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer text-right">
            <button
              type="submit"
              class="btn btn-success mr-2"
              @click="save()"
              ref="kt_save_changes"
            >
              Simpan
            </button>
          </div>
        </div>

        <InfoMsgDialog
          v-model="openInfoMsgDialog"
          :title="'Berjaya'"
          :content="'Kata laluan anda berjaya diset semula. Sila log masuk semula.'"
          :showProceedButton="true"
          :showBackButton="false"
          :delayOnProceed="false"
          proceedButton="OK"
          @onProceed="onProceed()"
        ></InfoMsgDialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

import { RESET_PASSWORD } from "@/core/services/store/user.module";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

// import InfoMsgDialog from "@/view/pages/widgets/MessageDialog.vue";

export default {
  name: "ResetPassword",
  components: {
    // InfoMsgDialog,
  },
  data() {
    return {
      password: "",
      openInfoMsgDialog: false,
    };
  },
  mounted() {
    const reset_password_form = KTUtil.getById("kt_reset_password_form");

    this.fv = formValidation(reset_password_form, {
      fields: {
        new_password: {
          validators: {
            notEmpty: {
              message: "Kata laluan baharu tidak boleh dibiarkan kosong",
            },
          },
        },
        verify_password: {
          validators: {
            notEmpty: {
              message: "Pengesahan kata laluan tidak boleh dibiarkan kosong",
            },
            identical: {
              compare: function () {
                return reset_password_form.querySelector(
                  '[name="new_password"]'
                ).value;
              },
              message:
                "Kata laluan baharu dan pengesahan kata laluan tidak sama",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton(),
      },
    });

    if (this.$route.query.act != "reset_password") {
      this.$router.replace({
        name: "Dashboard",
      });
    }
  },
  methods: {
    async save() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var new_password = this.$refs.new_password.value;
        var verify_password = this.$refs.verify_password.value;

        const submitButton = this.$refs["kt_save_changes"];

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        setTimeout(async () => {
          await this.$store
            .dispatch(RESET_PASSWORD, {
              fr: this.$route.query.fr,
              tid: this.$route.query.tid,
              act: this.$route.query.act,
              sub: this.$route.query.sub,
              new_password: new_password,
            })
            .then((response) => {
              // xxconsole.log(response);
              if (response.data.success == true) {
                submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
                );

                this.openInfoMsgDialog = true;
              }
            });
        }, 2000);
      });
    },
    onProceed() {
      this.$router.replace({
        name: this.$route.query.nx,
      });
    },
  },
};
</script>