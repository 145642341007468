var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", sm: "12", md: "8", lg: "6", xl: "6" },
            },
            [
              _c("div", { staticClass: "card card-custom gutter-b" }, [
                _c("div", { staticClass: "card-header py-3" }, [
                  _c(
                    "div",
                    { staticClass: "card-title align-items-start flex-column" },
                    [
                      _c(
                        "h5",
                        {
                          staticClass:
                            "card-label font-weight-bolder text-dark",
                        },
                        [_vm._v(" Kata laluan baharu ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-muted font-weight-bold font-size-sm mt-1",
                        },
                        [
                          _vm._v(
                            "Kata laluan baharu mestilah lain dari kata laluan asal."
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "card-body py-0 px-0" }, [
                  _c(
                    "form",
                    {
                      staticClass: "form",
                      attrs: { id: "kt_reset_password_form" },
                    },
                    [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "col-xl-3 col-lg-3 col-form-label text-alert",
                            },
                            [_vm._v("Kata laluan baharu")]
                          ),
                          _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                            _c("input", {
                              ref: "new_password",
                              staticClass:
                                "form-control form-control-lg form-control-solid",
                              attrs: {
                                type: "password",
                                value: "",
                                placeholder: "Kata laluan baharu",
                                name: "new_password",
                                minlength: "6",
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-xl-3 col-lg-3 col-form-label" },
                            [_vm._v("Pengesahan kata laluan")]
                          ),
                          _c("div", { staticClass: "col-lg-9 col-xl-6" }, [
                            _c("input", {
                              ref: "verify_password",
                              staticClass:
                                "form-control form-control-lg form-control-solid",
                              attrs: {
                                type: "password",
                                value: "",
                                placeholder: "Pengesahan kata laluan",
                                name: "verify_password",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "card-footer text-right" }, [
                  _c(
                    "button",
                    {
                      ref: "kt_save_changes",
                      staticClass: "btn btn-success mr-2",
                      attrs: { type: "submit" },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(" Simpan ")]
                  ),
                ]),
              ]),
              _c("InfoMsgDialog", {
                attrs: {
                  title: "Berjaya",
                  content:
                    "Kata laluan anda berjaya diset semula. Sila log masuk semula.",
                  showProceedButton: true,
                  showBackButton: false,
                  delayOnProceed: false,
                  proceedButton: "OK",
                },
                on: {
                  onProceed: function ($event) {
                    return _vm.onProceed()
                  },
                },
                model: {
                  value: _vm.openInfoMsgDialog,
                  callback: function ($$v) {
                    _vm.openInfoMsgDialog = $$v
                  },
                  expression: "openInfoMsgDialog",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }